import menu1 from "./menu1.jpeg";
import menu2 from "./menu2.jpeg";

// Returning them in an array
const menuImages = [
  menu1,
  menu2,
];

export default menuImages;
