import offer1 from "./offer-1.jpg";
import offer2 from "./offer-2.jpg";
import offer3 from "./offer-3.jpg";

// Returning them in an array
const menuImages = [
  offer1,
  offer2,
  offer3,
];

export default menuImages;
