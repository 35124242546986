import offer1 from "./offer-1.jpg";
import offer2 from "./offer-2.jpg";
import offer3 from "./offer-3.jpg";
import offer4 from "./offer-4.jpg";
import offer5 from "./offer-5.jpg";
import offer6 from "./offer-6.jpeg";

// Returning them in an array
const menuImages = [
  offer1,
  offer2,
  offer3,
  offer4,
  offer5,
  offer6
];

export default menuImages;
